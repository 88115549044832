import Vue from 'vue'
import Vuelidate from 'vuelidate'
import InputFacade from 'vue-input-facade'

Vue.use(Vuelidate)
Vue.use(InputFacade, {
  name: 'input-mask',
  tokens: {
    '#': { pattern: /\d/ },
    X: { pattern: /[0-9a-z]/i },
    S: { pattern: /[a-z]/i },
    A: { pattern: /[a-z]/i, transform: (v) => v.toLocaleUpperCase() },
    a: { pattern: /[a-z]/i, transform: (v) => v.toLocaleLowerCase() },
    l: { pattern: /./i, transform: v => v.toLocaleLowerCase() },
    '\\': { escape: true },
    '?': { optional: true },
    '*': { repeat: true },
    '|': { pipe: true },
    Z: { pattern: /[1-5]/}
  }
})

Vue.component('action-button', () => import('~/components/buttons/Action.vue'))
Vue.component('toggle-button', () => import('~/components/buttons/Toggle.vue'))
