import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _be9dc5d0 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _16c8f122 = () => interopDefault(import('../pages/cupom.vue' /* webpackChunkName: "pages/cupom" */))
const _ca324bc8 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _f161461c = () => interopDefault(import('../pages/ganhadores.vue' /* webpackChunkName: "pages/ganhadores" */))
const _47ee0056 = () => interopDefault(import('../pages/onde-trocar.vue' /* webpackChunkName: "pages/onde-trocar" */))
const _542e3f94 = () => interopDefault(import('../pages/perfil.vue' /* webpackChunkName: "pages/perfil" */))
const _698df9c2 = () => interopDefault(import('../pages/regulamentos.vue' /* webpackChunkName: "pages/regulamentos" */))
const _45214486 = () => interopDefault(import('../pages/admin/actions/index.vue' /* webpackChunkName: "pages/admin/actions/index" */))
const _376275e2 = () => interopDefault(import('../pages/admin/calculation.vue' /* webpackChunkName: "pages/admin/calculation" */))
const _2b3990b5 = () => interopDefault(import('../pages/admin/commands/index.vue' /* webpackChunkName: "pages/admin/commands/index" */))
const _27204862 = () => interopDefault(import('../pages/admin/cpf-block/index.vue' /* webpackChunkName: "pages/admin/cpf-block/index" */))
const _4a2d26da = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _0b8c0756 = () => interopDefault(import('../pages/admin/dispatches/index.vue' /* webpackChunkName: "pages/admin/dispatches/index" */))
const _1377aa11 = () => interopDefault(import('../pages/admin/email-templates/index.vue' /* webpackChunkName: "pages/admin/email-templates/index" */))
const _33901770 = () => interopDefault(import('../pages/admin/exports/index.vue' /* webpackChunkName: "pages/admin/exports/index" */))
const _6ea9fb42 = () => interopDefault(import('../pages/admin/faq/index.vue' /* webpackChunkName: "pages/admin/faq/index" */))
const _73e35cc9 = () => interopDefault(import('../pages/admin/indexes/index.vue' /* webpackChunkName: "pages/admin/indexes/index" */))
const _57259524 = () => interopDefault(import('../pages/admin/invalid-prizes/index.vue' /* webpackChunkName: "pages/admin/invalid-prizes/index" */))
const _225d68da = () => interopDefault(import('../pages/admin/invoices/index.vue' /* webpackChunkName: "pages/admin/invoices/index" */))
const _31662bf5 = () => interopDefault(import('../pages/admin/ips/index.vue' /* webpackChunkName: "pages/admin/ips/index" */))
const _f71b2766 = () => interopDefault(import('../pages/admin/permissions/index.vue' /* webpackChunkName: "pages/admin/permissions/index" */))
const _11a5e60e = () => interopDefault(import('../pages/admin/prizes/index.vue' /* webpackChunkName: "pages/admin/prizes/index" */))
const _0a697fa3 = () => interopDefault(import('../pages/admin/registrations/index.vue' /* webpackChunkName: "pages/admin/registrations/index" */))
const _5b789f92 = () => interopDefault(import('../pages/admin/regulations/index.vue' /* webpackChunkName: "pages/admin/regulations/index" */))
const _36139c4d = () => interopDefault(import('../pages/admin/statistics.vue' /* webpackChunkName: "pages/admin/statistics" */))
const _412cd9df = () => interopDefault(import('../pages/admin/stores/index.vue' /* webpackChunkName: "pages/admin/stores/index" */))
const _345d15f0 = () => interopDefault(import('../pages/admin/tickets/index.vue' /* webpackChunkName: "pages/admin/tickets/index" */))
const _0295f9c7 = () => interopDefault(import('../pages/admin/tokens/index.vue' /* webpackChunkName: "pages/admin/tokens/index" */))
const _79b52d27 = () => interopDefault(import('../pages/admin/total-tickets/index.vue' /* webpackChunkName: "pages/admin/total-tickets/index" */))
const _808f695e = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _ce035a46 = () => interopDefault(import('../pages/admin/winners/index.vue' /* webpackChunkName: "pages/admin/winners/index" */))
const _fd12cb6c = () => interopDefault(import('../pages/admin/winners-draw/index.vue' /* webpackChunkName: "pages/admin/winners-draw/index" */))
const _830f1f20 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _33eac2b0 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _3a8331a0 = () => interopDefault(import('../pages/admin/auth/forgot.vue' /* webpackChunkName: "pages/admin/auth/forgot" */))
const _0cb6f7ec = () => interopDefault(import('../pages/admin/auth/login.vue' /* webpackChunkName: "pages/admin/auth/login" */))
const _39610c54 = () => interopDefault(import('../pages/admin/auth/update.vue' /* webpackChunkName: "pages/admin/auth/update" */))
const _74b9d909 = () => interopDefault(import('../pages/admin/dispatches/create.vue' /* webpackChunkName: "pages/admin/dispatches/create" */))
const _f99c3a66 = () => interopDefault(import('../pages/admin/email-templates/create.vue' /* webpackChunkName: "pages/admin/email-templates/create" */))
const _7469943f = () => interopDefault(import('../pages/admin/faq/create.vue' /* webpackChunkName: "pages/admin/faq/create" */))
const _3b4f9d6c = () => interopDefault(import('../pages/admin/regulations/create.vue' /* webpackChunkName: "pages/admin/regulations/create" */))
const _0c22acbf = () => interopDefault(import('../pages/admin/stores/create.vue' /* webpackChunkName: "pages/admin/stores/create" */))
const _394c4d21 = () => interopDefault(import('../pages/admin/stores/import-csv.vue' /* webpackChunkName: "pages/admin/stores/import-csv" */))
const _77dd89d7 = () => interopDefault(import('../pages/admin/tokens/create.vue' /* webpackChunkName: "pages/admin/tokens/create" */))
const _5f05698d = () => interopDefault(import('../pages/admin/users/create.vue' /* webpackChunkName: "pages/admin/users/create" */))
const _55de0c98 = () => interopDefault(import('../pages/admin/winners-draw/create.vue' /* webpackChunkName: "pages/admin/winners-draw/create" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1d52ae87 = () => interopDefault(import('../pages/admin/auth/reset/_token.vue' /* webpackChunkName: "pages/admin/auth/reset/_token" */))
const _61d9bb46 = () => interopDefault(import('../pages/admin/email-templates/edit/_id.vue' /* webpackChunkName: "pages/admin/email-templates/edit/_id" */))
const _382a4f90 = () => interopDefault(import('../pages/admin/faq/edit/_id.vue' /* webpackChunkName: "pages/admin/faq/edit/_id" */))
const _ed196bb6 = () => interopDefault(import('../pages/admin/regulations/edit/_id.vue' /* webpackChunkName: "pages/admin/regulations/edit/_id" */))
const _71bfd0b8 = () => interopDefault(import('../pages/admin/stores/edit/_id.vue' /* webpackChunkName: "pages/admin/stores/edit/_id" */))
const _d232dbf4 = () => interopDefault(import('../pages/admin/users/edit/_id.vue' /* webpackChunkName: "pages/admin/users/edit/_id" */))
const _8f629726 = () => interopDefault(import('../pages/admin/winners-draw/edit/_id.vue' /* webpackChunkName: "pages/admin/winners-draw/edit/_id" */))
const _5322b06e = () => interopDefault(import('../pages/admin/actions/_id.vue' /* webpackChunkName: "pages/admin/actions/_id" */))
const _4b550ffd = () => interopDefault(import('../pages/admin/dispatches/_id.vue' /* webpackChunkName: "pages/admin/dispatches/_id" */))
const _56c2dee8 = () => interopDefault(import('../pages/admin/invalid-prizes/_id.vue' /* webpackChunkName: "pages/admin/invalid-prizes/_id" */))
const _475316bb = () => interopDefault(import('../pages/admin/invoices/_id.vue' /* webpackChunkName: "pages/admin/invoices/_id" */))
const _21b1c1a9 = () => interopDefault(import('../pages/admin/ips/_ip.vue' /* webpackChunkName: "pages/admin/ips/_ip" */))
const _d41bd814 = () => interopDefault(import('../pages/admin/prizes/_id.vue' /* webpackChunkName: "pages/admin/prizes/_id" */))
const _4eede26a = () => interopDefault(import('../pages/admin/registrations/_id.vue' /* webpackChunkName: "pages/admin/registrations/_id" */))
const _5136212f = () => interopDefault(import('../pages/admin/validation/_prize.vue' /* webpackChunkName: "pages/admin/validation/_prize" */))
const _1730c7c7 = () => interopDefault(import('../pages/auth/reset/_token.vue' /* webpackChunkName: "pages/auth/reset/_token" */))
const _c4c5befc = () => interopDefault(import('../pages/unsubscribe/_id.vue' /* webpackChunkName: "pages/unsubscribe/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _be9dc5d0,
    name: "admin"
  }, {
    path: "/cupom",
    component: _16c8f122,
    name: "cupom"
  }, {
    path: "/faq",
    component: _ca324bc8,
    name: "faq"
  }, {
    path: "/ganhadores",
    component: _f161461c,
    name: "ganhadores"
  }, {
    path: "/onde-trocar",
    component: _47ee0056,
    name: "onde-trocar"
  }, {
    path: "/perfil",
    component: _542e3f94,
    name: "perfil"
  }, {
    path: "/regulamentos",
    component: _698df9c2,
    name: "regulamentos"
  }, {
    path: "/admin/actions",
    component: _45214486,
    name: "admin-actions"
  }, {
    path: "/admin/calculation",
    component: _376275e2,
    name: "admin-calculation"
  }, {
    path: "/admin/commands",
    component: _2b3990b5,
    name: "admin-commands"
  }, {
    path: "/admin/cpf-block",
    component: _27204862,
    name: "admin-cpf-block"
  }, {
    path: "/admin/dashboard",
    component: _4a2d26da,
    name: "admin-dashboard"
  }, {
    path: "/admin/dispatches",
    component: _0b8c0756,
    name: "admin-dispatches"
  }, {
    path: "/admin/email-templates",
    component: _1377aa11,
    name: "admin-email-templates"
  }, {
    path: "/admin/exports",
    component: _33901770,
    name: "admin-exports"
  }, {
    path: "/admin/faq",
    component: _6ea9fb42,
    name: "admin-faq"
  }, {
    path: "/admin/indexes",
    component: _73e35cc9,
    name: "admin-indexes"
  }, {
    path: "/admin/invalid-prizes",
    component: _57259524,
    name: "admin-invalid-prizes"
  }, {
    path: "/admin/invoices",
    component: _225d68da,
    name: "admin-invoices"
  }, {
    path: "/admin/ips",
    component: _31662bf5,
    name: "admin-ips"
  }, {
    path: "/admin/permissions",
    component: _f71b2766,
    name: "admin-permissions"
  }, {
    path: "/admin/prizes",
    component: _11a5e60e,
    name: "admin-prizes"
  }, {
    path: "/admin/registrations",
    component: _0a697fa3,
    name: "admin-registrations"
  }, {
    path: "/admin/regulations",
    component: _5b789f92,
    name: "admin-regulations"
  }, {
    path: "/admin/statistics",
    component: _36139c4d,
    name: "admin-statistics"
  }, {
    path: "/admin/stores",
    component: _412cd9df,
    name: "admin-stores"
  }, {
    path: "/admin/tickets",
    component: _345d15f0,
    name: "admin-tickets"
  }, {
    path: "/admin/tokens",
    component: _0295f9c7,
    name: "admin-tokens"
  }, {
    path: "/admin/total-tickets",
    component: _79b52d27,
    name: "admin-total-tickets"
  }, {
    path: "/admin/users",
    component: _808f695e,
    name: "admin-users"
  }, {
    path: "/admin/winners",
    component: _ce035a46,
    name: "admin-winners"
  }, {
    path: "/admin/winners-draw",
    component: _fd12cb6c,
    name: "admin-winners-draw"
  }, {
    path: "/forgot",
    component: _830f1f20,
    name: "auth-forgot"
  }, {
    path: "/login",
    component: _8e1272a8,
    name: "auth-login"
  }, {
    path: "/register",
    component: _33eac2b0,
    name: "auth-register"
  }, {
    path: "/admin/forgot",
    component: _3a8331a0,
    name: "admin-auth-forgot"
  }, {
    path: "/admin/login",
    component: _0cb6f7ec,
    name: "admin-auth-login"
  }, {
    path: "/admin/update",
    component: _39610c54,
    name: "admin-auth-update"
  }, {
    path: "/admin/dispatches/create",
    component: _74b9d909,
    name: "admin-dispatches-create"
  }, {
    path: "/admin/email-templates/create",
    component: _f99c3a66,
    name: "admin-email-templates-create"
  }, {
    path: "/admin/faq/create",
    component: _7469943f,
    name: "admin-faq-create"
  }, {
    path: "/admin/regulations/create",
    component: _3b4f9d6c,
    name: "admin-regulations-create"
  }, {
    path: "/admin/stores/create",
    component: _0c22acbf,
    name: "admin-stores-create"
  }, {
    path: "/admin/stores/import-csv",
    component: _394c4d21,
    name: "admin-stores-import-csv"
  }, {
    path: "/admin/tokens/create",
    component: _77dd89d7,
    name: "admin-tokens-create"
  }, {
    path: "/admin/users/create",
    component: _5f05698d,
    name: "admin-users-create"
  }, {
    path: "/admin/winners-draw/create",
    component: _55de0c98,
    name: "admin-winners-draw-create"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/admin/reset/:token?",
    component: _1d52ae87,
    name: "admin-auth-reset-token"
  }, {
    path: "/admin/email-templates/edit/:id",
    component: _61d9bb46,
    name: "admin-email-templates-edit-id"
  }, {
    path: "/admin/faq/edit/:id?",
    component: _382a4f90,
    name: "admin-faq-edit-id"
  }, {
    path: "/admin/regulations/edit/:id?",
    component: _ed196bb6,
    name: "admin-regulations-edit-id"
  }, {
    path: "/admin/stores/edit/:id?",
    component: _71bfd0b8,
    name: "admin-stores-edit-id"
  }, {
    path: "/admin/users/edit/:id?",
    component: _d232dbf4,
    name: "admin-users-edit-id"
  }, {
    path: "/admin/winners-draw/edit/:id",
    component: _8f629726,
    name: "admin-winners-draw-edit-id"
  }, {
    path: "/admin/actions/:id",
    component: _5322b06e,
    name: "admin-actions-id"
  }, {
    path: "/admin/dispatches/:id",
    component: _4b550ffd,
    name: "admin-dispatches-id"
  }, {
    path: "/admin/invalid-prizes/:id?",
    component: _56c2dee8,
    name: "admin-invalid-prizes-id"
  }, {
    path: "/admin/invoices/:id",
    component: _475316bb,
    name: "admin-invoices-id"
  }, {
    path: "/admin/ips/:ip",
    component: _21b1c1a9,
    name: "admin-ips-ip"
  }, {
    path: "/admin/prizes/:id",
    component: _d41bd814,
    name: "admin-prizes-id"
  }, {
    path: "/admin/registrations/:id",
    component: _4eede26a,
    name: "admin-registrations-id"
  }, {
    path: "/admin/validation/:prize?",
    component: _5136212f,
    name: "admin-validation-prize"
  }, {
    path: "/reset/:token?",
    component: _1730c7c7,
    name: "auth-reset-token"
  }, {
    path: "/unsubscribe/:id?",
    component: _c4c5befc,
    name: "unsubscribe-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
